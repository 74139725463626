<template>
  <b-modal
    id="add-commission-modal"
    v-model="open"
    hide-footer
    size="md"
    centered
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
      style="flex: 1"
      class="d-flex flex-column"
    >
      <b-container class="tw-p-8">
        <h1 class="text-center mb-4">{{ $t(title) }}</h1>
        <feather-icon
          class="cursor-pointer position-absolute"
          style="top: 0; right: 1rem"
          icon="XIcon"
          size="22"
          @click="close"
        />
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Company')"
                label-for="company-select"
              >
                <v-select
                  id="company-select"
                  v-model="companyId"
                  label="name"
                  :placeholder="$t('Search Company')"
                  :options="companies"
                  :get-option-label="option => option.name"
                  :reduce="option => option.id"
                  :clearable="true"
                  @option:selected="selectCompany"
                  @search="onSearchCompanies"
                >
                  <span slot="no-options">
                    <span v-if="query.length">{{ $t(`No matching records found`) }}</span>
                    <span v-else>{{ $t(`Search by name`) }}</span>
                  </span>
                </v-select>
                <b-form-invalid-feedback v-if="errors.companyId">{{ errors.companyId }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Date Range')"
                label-for="period"
              >
                <b-row>
                  <b-col cols="6">
                    <date-picker
                      v-model="startAt"
                      value-type="DD-MM-YYYY"
                      format="DD-MM-YYYY"
                      type="date"
                      :placeholder="$t('From')"
                      class="w-100"
                    ></date-picker>
                    <b-form-invalid-feedback v-if="errors.startAt">{{ errors.startAt }}</b-form-invalid-feedback>
                  </b-col>
                  <b-col cols="6">
                    <date-picker
                      id="ends-at"
                      v-model="endAt"
                      value-type="DD-MM-YYYY"
                      format="DD-MM-YYYY"
                      type="date"
                      :placeholder="$t('To')"
                      class="w-100"
                    ></date-picker>
                    <b-form-invalid-feedback v-if="errors.endAt">{{ errors.endAt }}</b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Commission Model')"
                label-for="model-select"
              >
                <v-select
                  id="model-select"
                  v-model="modelId"
                  label="name"
                  :placeholder="$t('Select commission model')"
                  :options="models"
                  :get-option-label="option => option.name"
                  :reduce="option => option.id"
                  :clearable="true"
                >
                  <span slot="no-options">
                    <span v-if="query.length">{{ $t(`No matching records found`) }}</span>
                    <span v-else>{{ $t(`Search by name`) }}</span>
                  </span>
                </v-select>
                <b-form-invalid-feedback v-if="errors.modelId">{{ errors.modelId }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div class="d-flex justify-content-end align-items-center tw-mt-6">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mr-1"
            @click="close"
          >
            {{ $t('Close') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click="handleSave"
          >
            {{ $t('Save') }}
          </b-button>
        </div>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import companyService from '@/services/company'
import { debounce } from 'lodash'
import GlobalToastNotification from '@/components/shared/GlobalToastNotification.vue'
import { BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSpinbutton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import commissionsService from '@/services/commissions'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import * as Yup from 'yup'
import modelsService from '@/services/models'
import dayjs from 'dayjs'

export default {
  components: {
    BCardCode,
    DatePicker,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSpinbutton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dayjs,
      loading: false,
      title: 'Create Commission',
      open: false,
      errors: [],
      query: '',
      id: null,
      uuid: null,
      name: null,
      startAt: null,
      endAt: null,
      companyId: null,
      companyName: null,
      modelId: null,
      modelName: null,
      modelType: null,
      modelAgreementType: null,
      companies: [],
      models: [],
    }
  },
  watch: {
    form: {
      handler() {
        this.errors = []
      },
      deep: true,
    },
  },
  methods: {
    callToast(message) {
      this.$toast(
        {
          component: GlobalToastNotification,
          props: {
            message,
          },
        },
        {
          closeButton: false,
          timeout: 7000,
          containerClassName: 'toast-notification',
        },
      )
    },
    convertToISOString(dateString) {
      const [day, month, year] = dateString.split('-')

      const date = new Date(`${year}-${month}-${day}`)

      if (isNaN(date.getTime())) {
        return 'Invalid date'
      }

      // Convert the Date object to an ISO string
      const isoString = date.toISOString()

      return isoString
    },
    async handleSave() {
      const schema = Yup.object().shape({
        companyId: Yup.string().nullable().required('Required'),
        modelId: Yup.string().nullable().required('Rrequired'),
        startAt: Yup.string().nullable().required('Required'),
        endAt: Yup.string()
          .nullable()
          .test('is-greater-than-start-date', this.$t('From date cannot be greater than To date'), (item, testContext) => {
            const { startAt } = testContext.parent
            const startDate = new Date(this.convertToISOString(startAt))
            const endDate = new Date(this.convertToISOString(item))

            return endDate >= startDate
          })
          .required('Required'),
      })

      try {
        await schema.validate(
          {
            companyId: this.companyId,
            modelId: this.modelId,
            coverage: this.coverage,
            startAt: this.startAt,
            endAt: this.endAt,
          },
          { abortEarly: false },
        )

        const post = {
          companyId: this.companyId,
          modelId: this.modelId,
          name: this.companyName,
          startAt: this.convertToISOString(this.startAt),
          endAt: this.convertToISOString(this.endAt),
        }

        this.loading = true
        const res = this.uuid ? await commissionsService.updateCommission(this.uuid, post) : await commissionsService.createNewCommission(post)

        this.callToast({ subject: this.title, message: this.uuid ? 'Commission updated successfully' : 'Commission added successfully' })
        this.$emit('submit', res)
        this.close()
      } catch (error) {
        // Handle validation errors
        if (error instanceof Yup.ValidationError) {
          const fieldErrors = {}
          error.inner.forEach(e => {
            fieldErrors[e.path] = e.message
          })
          this.errors = fieldErrors
        }
      } finally {
        this.loading = false
      }
    },

    async getModels() {
      const queryx = {
        size: 200,
      }
      const res = await modelsService.getModels(queryx)
      if (!res.data) return

      this.models = res.data.pageItems
    },

    onSearchCompanies(search, loading) {
      this.query = search
      if (search.trim() === '') return

      loading(true)
      this.searchCompanies(search, loading, this)
    },

    selectCompany(data) {
      if (!data || !data.id || !data.name) return
      this.companyId = data.id
      this.companyName = data.name
    },

    selectModel(data) {
      this.modelId = data.id
      this.modelName = data.name
    },

    searchCompanies: debounce(async (search, loading, vm) => {
      const res = await companyService.autocomplete(search.trim())
      if (res && res.data.length) {
        if (res.data.length === 1) {
          vm.company = res.data[0]
        }
        vm.companies = res.data
      }
      loading(false)
    }, 200),

    async openModal({ item, isClone }) {
      this.open = true
      this.loading = true

      await this.getModels()

      if (item) {
        this.title = isClone ? 'Clone Commission' : 'Edit Commission'
        const { data } = await commissionsService.getCommission(item.uuid)

        if (data) {
          if (!isClone) {
            this.id = data.id
            this.uuid = data.uuid
          }
          this.name = data.name
          this.startAt = this.dayjs(data.startAt).format('DD-MM-YYYY')
          this.endAt = this.dayjs(data.endAt).format('DD-MM-YYYY')
          this.companyId = data.companyId
          this.companyName = data.companyName
          this.modelId = data.modelId
          this.modelName = data.modelName
          this.modelType = data.modelType
          this.modelAgreementType = data.modelAgreementType
          this.companies = [
            {
              id: data.companyId,
              name: data.companyName,
            },
          ]
        }
      }

      this.loading = false
    },

    async close() {
      this.title = 'Create Commission'
      this.open = false
      this.loading = false
      this.id = null
      this.uuid = null
      this.name = null
      this.startAt = null
      this.endAt = null
      this.companyId = null
      this.companyName = null
      this.modelId = null
      this.modelName = null
      this.modelType = null
      this.modelAgreementType = null
      this.companies = []
      this.models = []
      this.errors = []
    },
  },
}
</script>
